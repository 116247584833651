// .h1 {
//   font-family: 'Apercu Pro';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 80px;
//   line-height: 120%;
//   color: #FFFFFF;
// }
.font-small {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #8d959c;
}

.mainHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 120%;
  color: #ffffff;
}

.h2 {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  color: #ffffff;
}

.mainHeading_dark {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 120%;
  color: #0d1e3a;
}

.subHeadingWhite {
  height: 30px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  color: #ffffff;
}
.sub_heading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  color: #0d1e3a;
  height: 38px;
}
.sub_heading_2 {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  color: #0d1e3a;
}

.subHeadingDark {
  width: 147px;
  height: 58px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 100%;
  color: #0d1e3a;
}
.sub_text_dark {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #0d1e3a;
}
.sub_purple {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #8437f9;
}
.eng_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #0d1e3a;
}
.eng_para {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #8d959c;
}
.text_red {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #e25c5c;
}
.textWhite {
  height: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ffffff;
}
.textWhiteSmall {
  height: 16px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #ffffff;
}
.textGrey {
  margin: auto;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #e2e4e5;
}
.JointextGrey {
  margin-top: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #e2e4e5;
}
.textGreyDark {
  margin: 0px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #8d959c;
}
.textMedium {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  color: #ffffff;
}

.textSmall {
  text-align: center;
  width: 350px;
  margin: 0px auto 30px;
}

.textMedium {
  width: 400px;
}

.xxxl {
  font-size: 36px;
}

.textLarge {
  width: 550px;
}
.textDark {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #0d1e3a;
}

@media screen and (max-width: 600px) {
  .mainHeading {
    font-size: 37px;
    line-height: 120%;
    margin-top: 20px;
    text-align: center;
  }

  .h1 {
    font-size: 45px;
    // line-height: 100%;
    margin-top: 20px;
  }

  .textWhite {
    margin-top: 30px;
    font-size: 14px;
    line-height: 100%;
  }

  .textSmall {
    width: 250px;
  }

  .textGrey {
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .JointextGrey {
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .mainHeading_dark {
    font-size: 36px;
  }
  .sub_heading {
    font-size: 19px;
    line-height: 120%;
    height: none;
  }
}
