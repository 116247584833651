button {
    border: 0px
}

.main {
    width: 100vw;
    height: 100vh;
    padding: 0 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.certificate_container {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 50px;
}

.certificate_container .certificate {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certificate_image {
    width: 100%;
    object-fit: cover;
}

.certificate_container .course_result {
    width: 30%;
    padding: 50px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}

.check {
    /* font-size: 60px; */
    font-size: 48px;
    color: #f9c80e;
}

.share {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 30px;
}

.share-button {
  color: #fff;
  background-color: #16325b;
  padding: 25px;
  border-radius: 6px;
}

.share-button:hover {
  cursor: pointer;
  background-color: #05192d;
}

.next-course-button {
    background-color: #f9c80e;
    padding: 25px;
    border-radius: 6px;
}

.next-course-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.remark {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
}