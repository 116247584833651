.note__container {
  position: absolute;
  bottom: 0;
  height: 75%;
  width: 100%;
  background-color: white;
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
}
.ontop {
  position: absolute;
  display: block;
  /* top: 0; */
  /* left: 0; */
  z-index: 1;
}

.note__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 1rem;
}
.note__video-info {
  padding: 1rem;
}
.note__video-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.note__input-container {
  margin-top: 1rem;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid #dbdbdb;
  padding: 1rem 1rem 0.5rem 1rem;
  /* height: 3rem; */
}
.note__input-footer {
  display: flex;
  align-items: center;
}
.note__input {
  width: 100%;
  border: none;
  height: 100%;
  font-size: 1rem;
  outline: none;
}
.note__input-title {
  font-size: 1rem;
  font-weight: bold;
}
.post-btn {
  color: #0095f6;
  font-weight: bold;
  padding: 1rem;
}
