.teamscore-card {
  width: 100%;
  max-width: 350px;
  background: #1f2c42;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 20px 30px;
  color: #fff;

  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #ffffff;
  margin-top: 1rem;
}
.teamscore-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.teamscore-circle {
  height: 6rem;
  width: 6rem;
}

