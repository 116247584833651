.invite-link-container {
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  margin: 10px;
  cursor: pointer;
}

.invite-link-container .invite-span {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--purple);
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  color: white;
  margin-bottom: 10px;
}

.invite-link-container code {
  color: white;
  word-wrap: break-word;
  width: 80%;
}

.invite-link-container .copy-link {
  font-size: 1.5rem;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}
