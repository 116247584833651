.admin-ide-nav {
  height: 5vh;
  width: 100vw;
  background-color: #333;
  z-index: 5;
  position: absolute;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.admin-ide-nav-edit-mode {
  background-color: #ff930f;
  color: #333;
}

.admin-ide-container {
  height: 95vh;
  width: 100vw;
  position: absolute;
  top: 5vh;
}
.admin-sandbox-sidebar {
  position: absolute;
  bottom: 0;
  height: 95vh;
  width: 20vw;
  background-color: #f5f5f5;
  z-index: 5;
}
.admin-videos-list {
  right: 0;
}

.admin-ide-btn {
  border: 1px #f5f5f5 solid;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    display: flex;
  }
}
.btn-purple {
  background-color: var(--purple);
  color: white;
}

.admin-ide-btn-active {
  background-color: #f5f5f5;
  color: #333;
}

.admin-ide-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
