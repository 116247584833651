.main {
  height: 100%;
  position: relative;
  padding-top: 14px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .main {
    flex-direction: row;
    max-width: 1500px;
  }
}
