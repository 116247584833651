.contentdrawer-content {
    margin-bottom: 1rem;
  height: calc(100% - 10vh);
  width: 100%;
  /* background: yellow; */
  overflow: hidden;
  overflow-y: scroll;
}
.contentdrawer-content::-webkit-scrollbar {
  display: none;
}
.contentdrawer-content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: solid 0.5px grey;
}
.contentdrawer-content-item-title {
    display: flex;
    align-items: center;
}
.content-icon {
    color: #8556f8;
}