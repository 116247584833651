.rec-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.rec-container h4 {
    color: white;
}

.rec-container button {
    background-color: inherit;
    color: gray;
    border: 0px;
}

.rec-container .button {
    background-color: #f9c80e;
    color: black;
    padding: 25px;
    border-radius: 6px;
}

.rec-container .button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.dash-comp-icon {
  font-size: 3rem;
  color: white;
  z-index: 2;
  margin-right: -22px;
}
.dash-message-parent {
  display: flex;
  align-items: center;
}
.dash-competition-card {
  margin: 1rem;
  border-radius: 3px;
  padding: 1rem;

  /* min-width: 300px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}