.onb-wrapper {
  display: flex;
  justify-content: center;
}
.onb-container {
  margin: 1rem;
  // height: calc(100vh - 10vh);
  height: calc(100vh - 15vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  min-width: 600px;
}

.onb-header {
  margin: 1rem 0rem;
  display: flex;
  width: 100%;
  align-items: center;
  .onb-back-btn {
    color: white;
    display: inline-block;
    height: 35px;
    width: 35px;
  }
  .onb-progress-bar {
    flex-grow: 1;
    margin-left: 10px;
  }
}

.onb-body {
  flex-grow: 1;
  position: relative;
  .onb-question {
    margin-top: 30px;
    .onb-option {
      cursor: pointer;
      margin: 1rem 0;
      background-color: white;
      padding: 24px;
      box-shadow: 2px 4px lightblue;
      border-radius: 1.5rem;
      span {
        font-size: 0.8rem;
        opacity: 50%;
      }
    }
    .onb-option-default {
      background-color: white;
      color: var(--blue);
    }
    .onb-option-selected {
      background-color: #bf8ee0;
      color: white;
    }
    .onb-option-success {
      background-color: var(--green);
      color: white;
    }
    .onb-option-danger {
      background-color: var(--red);
      color: white;
    }
  }
}

.onb-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.onb-footer {
  width: 100%;

  button {
    width: 100%;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
    padding: 20px;
  }
}
.onb-footer-sticky {
  position: absolute;
  bottom: 15px;
  
  left: 0;
  // margin: 10px;
  width: 100%;
}
.onb-footer-complete-btn {
  background: #00d395;
}

.onb-signup-container {
  padding: 1rem;
}

@media only screen and (max-width: 600px) {
  .onb-container {
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
  }
}
