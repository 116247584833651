.zcoinicon {
    width: 6rem;
}

.wallet-ballance-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 10px;
    cursor: pointer;
}

/* .wallet-ballance-container:hover {
    background: #f9c80e;
    color: #1f2c42;
} */