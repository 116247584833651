.qr-footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: black;
  height: 7vh;
  width: 100%;
  display: none;
  max-width: 540px;
  padding: 2rem 0rem;
}

.qr-footer-hide {
  display: none;
}

.navbar-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .qr-footer {
    display: block;
  }
  .qr-footer-hide {
    display: none;
  }
}
