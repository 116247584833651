.container {
  /* padding: 3rem; */
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
}

.container1 {
  padding: 28px 48px 48px 48px !important;
  /* padding: 0; */
}

/* .loggedContainer {
  padding-left: 78px;
} */

.title {
  color: #ffffff;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  font-family: "Apercu Pro" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2c3131;

  width: 225px;
  height: 50px;
  background: #f9c80e;
  border-radius: 4px;
}

.subContainer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  column-count: 3;
  margin-top: 2.5rem;
}

.cardContainer {
  /* display: inline-block; */
  /* width: 100%; */
  width: 33.3%;
  min-width: 310px;
  border-radius: 6px;
  padding: 10px;
  /* margin-top: 30px; */
  cursor: pointer;
}

.card {
  position: relative;
  z-index: 1;
  padding: 20px;
  /* padding-bottom: 75px; */
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  /* transform: translateY(-20px); */
  height: 200px;
  overflow: hidden;
}

.image {
  position: relative;
  width: 100%;
}

.noimage {
  display: none;
}

.image > img {
  width: 100%;
  border-radius: 6px;
  margin-bottom: -10px;
}

.playIcon {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  background: #f9c80e;
  box-shadow: 0px 4px 18px rgba(249, 200, 14, 0.47);
  border-radius: 100%;
}

/* .playIcon {
  position: absolute;
  background-color: black;
  border-radius: 100%;
  border: 0px;
  color: #f9c80e;
  font-size: 5rem;
  cursor: pointer;
  left: 20px;
  top: 20px;
} */

.cardText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  color: #909090;
}

.cardBottom {
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
  display: flex;
  align-items: center;
  margin-top: 44px;
}

.userImg {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 15px;
}

.modalBody > * > * {
  background-color: unset !important;
}

.modalHeader > * {
  padding: 1px 5px !important;
}

.closeBtn {
  padding: 4px 8px !important;
  height: fit-content !important;
  margin: auto 0 0 auto !important;
}

.modal-content > div {
  height: 0 !important;
  width: 0 !important;
}

@media screen and (max-width: 1500px) {
  .card {
    height: 220px;
  }
}

@media screen and (max-width: 1300px) {
  .card {
    height: 230px;
  }
}

@media screen and (max-width: 800px) {
  .card {
    height: unset;
  }
}

@media screen and (max-width: 450px) {
  .container {
    padding: 28px 15px 48px 15px;
  }

  .container1 {
    padding: 28px 15px 48px 15px;
  }

  /* .loggedContainer {
    padding-left: 45px;
  } */

  .cardContainer {
    width: 100%;
    min-width: 270px;
    margin-top: 40px;
    padding: 0;
  }
}

@media screen and (max-width: 391px) {
  .container {
    padding: 28px 10px 48px 10px;
  }

  .container1 {
    padding: 28px 10px 48px 10px;
  }

  /* .loggedContainer {
    padding-left: 40px;
  } */
}

@media screen and (max-width: 376px) {
  .container {
    padding: 28px 5px 48px 5px;
  }

  .container1 {
    padding: 28px 10px 48px 10px;
  }

  /* .loggedContainer {
    padding-left: 35px;
  } */

  .cardText > .right {
    right: 16px;
    bottom: 16px;
  }
}
