.container {
  background-color: #fff;
  padding: 180px 70px;
}
.text_box {
  width: 375px;
  height: 66px;
  margin: 26px 0px 36px;
}
.text {
  width: 71px;
  height: 20px; 
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: #8437f9;
}
.text_cont {
  width: 417px;
}
.text_grey {
  width: 143px;
  height: 22px;
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #8d959c;
}
.justify {
  margin: 0px 0px 0px auto;
}
.accordion_head {
  height: 29px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #0d1e3a;
  margin-left: 30px;
}

.position {
  position: relative;
  right: -112%;
  display: flex;
  align-self: center;
  height: 22px;

  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #8d959c;
}

.grey_text {
  height: 22px;
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #8d959c;
  margin-left: auto !important;
}

.truncate {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub_heading {
  //height: 22px;
  // margin-left: 15px;
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  word-wrap: break-word;
  flex-wrap: wrap;
  color: #0d1e3a;
}
.rotate {
  transform: rotate(90deg);
  transition: all;
}

.margin_arrow {
  margin-right: 25px;
  margin-left: 7px;
}
.acc_bottom {
  border-bottom: 1px solid rgba(13, 30, 58, 0.15);
}
.acc_bottom_none {
  border: none;
}

.accordion_none {
  display: none;
}
.accordion_show {
  display: block;
}
.box {
  border: 1px solid rgba(13, 30, 58, 0.15) !important;
}

.video_border {
  background: white;
  border: 0.5px solid rgba(13, 30, 58, 0.15);
  padding: 15px 15px;
  cursor: pointer;
}

.highlight {
  color: #4c00ff;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 90px 70px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding: 50px 10px;
  }
}
