.compiler-nav {
  height: 5vh;
  width: 100vw;
  background-color: #333;

  // position: absolute;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.compiler-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 5;
  // z-index: 9999;
}
.compiler-question-window {
  width: 30vw;
  height: 100vh;
  padding: 10px;
  background-color: white;
}
.compiler-editor {
  width: 70vw;
  background-color: #333;
  height: 70vh;
}
.compiler-console {
  background-color: #333;
  width: 100%;
  height: 30vh;

  .tab-content {
    height: 30vh;
    z-index: 3;
    overflow: auto;
  }
}

.compiler-question-window-split {
  width: 30vw;
}
.code-editor-container-split {
  width: 70vw;
  height: 70vh;

  .compiler-console-split {
    height: 30vh;
  }
}

.compiler-question-window-editor {
  width: 5vw;
}
.code-editor-container-editor {
  width: 95vw;
  height: 70vh;

  .compiler-console-editor {
    height: 30vh;
  }
}

.nav-right {
  button {
    background: var(--purple);
    border: none;
    color: white;
    padding: 5px 25px;
    margin-right: 10px;
    border-radius: 3px;
  }
  .success-btn {
    margin-right: 10px;
    border-radius: 3px;
    background-color: var(--green);
  }
}

.split {
  display: flex;
  flex-direction: row;
  ::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
    // background: #333;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: gainsboro;
    cursor: pointer;

  }
}

.split-col {
  display: flex;
  flex-direction: column;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
  z-index: 2;
}
.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
  z-index: 2;
}
