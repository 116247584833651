* {
    margin: 0 0;
    box-sizing: border-box;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    letter-spacing: 0.63px;
}
.faq_main {
    padding: 80px 0px;
    color: #F7F1EC;
}
.faq_main .accordion {
    overflow-anchor: none;
    box-sizing: border-box;
}

.faq_main .accordion .cardFaq .card-head h4 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.faq-answer {
    /* height: 66px; */
  
    /* Paragraph */
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    /* or 22px */
  
    color: #8d959c;
  }
.faq_main .accordion .cardFaq .card-head h4.collapsed svg {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.faq_main .card-body-mine, .faq_main header p {
    font-size: 20px;
    font-weight: 500;
}

.faq_main .card-body-mine:not(.faq_main .accordion .card:first-child .card-body-mine) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #909090;
}

.cardFaq {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
}

.cardFaq{
    border-bottom: 1px solid #98989863;
}
.accordion {
    border-top: 1px solid #98989863;
}

.card-head {
    padding-bottom: 20px;
    padding-top: 20px;
}

.question-heading {
    text-align: left;
  /* H6 */
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: -10px;
  width: 98%;
}
.heading {
    font-family: 'Apercu Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 120%;
    /* identical to box height, or 53px */
    color: #FFFFFF;
  }

@media only screen and (max-width: 576px) {
    .faq_main .accordion .card .card-head h4 {
        font-size: 18px;
    }
    .faq_main .accordion .card .card-head h4 svg {
        width: 16px;
    }
    .faq_main header p {
        text-align: center;
    }
    .faq_main .card-body-mine, .faq_main header p {
        font-size: 16px;
    }
}