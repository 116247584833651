.next-course-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.text {
    width: 40vw;
    color: white;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.next-course-container .button {
    background-color: #f9c80e;
    color: black;
    padding: 15px 25px;
    border-radius: 6px;
}