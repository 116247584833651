.videoContainer {
  background-color: rgba(0,0,0,0);
  background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 0%;
  height: 20%;
  bottom: 0;
}
.videoContainer_skip {
  height: 30%;
  left: 0;
}

.maxVideoContainer {
  background-image: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.1));
  height: 40%;
}

.videoFooter {
  color: white;
  padding-right: 50px;
  margin-top: 20px;
  margin-left: 40px;
}
.quizOptions {
  margin-bottom: 1rem;
}

.videoFooter__text {
  flex: 1;
  padding-right: 50px;
}

.videoFooter__text > p {
  padding-bottom: 20px;
}

.videoFooter__icon {
  position: absolute;
}

.videoFooter__ticker > .ticker {
  height: fit-content;
  margin-left: 30px;
  width: 60%;
}

.videoFooter__text > h3 {
  padding-bottom: 20px;
}
.post-pic {
  display: flex;
  align-items: center;
}
.post-pic img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-name {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: 800;
}
.video-caption {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
}
.signup-cta {
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  font-weight: 900;
  font-size: 1rem;
  padding: 5px;
  border: whitesmoke 1px solid;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .videoFooter {
    margin-left: 20px;
  }
  .videoContainer {
    position: absolute;
    bottom: 14vh;
  }
}
