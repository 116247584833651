.minimizeBtn {
  display: inline-block;
  margin: 0;
  padding: 0.4em;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  background: #17325b;
  color: rgb(255, 255, 255);
}

.minimizeBtn:hover {
  background: #0f213c;
}

.speedBtn {
  display: inline-block;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  background: #17325b;
  border-right: 0.7px #0f213c solid;
  border-left: 0.7px #0f213c solid;
  color: rgb(255, 255, 255);
}

.supportBtn {
  position: fixed;
  top: 338px;
  right: 20px;
  width: 155px;
  text-align: center;
  background-color: #8437f9 !important;
  border-radius: 18px !important;
  z-index: 10001;
  padding: 5px 12px !important;

  display: inline-block;
  margin: 0;
  padding: 0.5em;
  /* border-radius: 0; */
  cursor: pointer;
  background: #17325b;
  color: rgb(255, 255, 255);
}

.supportBtn:hover {
  background: #0f213c;
}

.border-danger {
  border-color: #dc3545 !important;
}

.toastOn {
  position: fixed;
  top: 385px;
  right: 20px;
  width: 155px;
  text-align: center;
  background-color: #8437f9 !important;
  border-radius: 18px;
  z-index: 10001;
  padding: 5px 12px !important;

  display: inline-block;
  margin: 0;
  padding: 0.4em;
  /* border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; */
  cursor: pointer;
  background: #004e21;
  color: rgb(255, 255, 255);
  z-index: 10000;
}

.toastOff {
  position: fixed;
  top: 385px;
  right: 20px;
  width: 155px;
  text-align: center;
  color: #fff !important;
  border-radius: 18px;
  z-index: 10001;
  padding: 5px 12px !important;

  display: inline-block;
  margin: 0;
  padding: 0.4em;
  /* border-bottom-left-radius: 4px;
  border-top-left-radius: 4px; */
  cursor: pointer;
  background: #863859;
  z-index: 10000;
}

.minimizeBtnPop {
  display: inline-block;
  margin: 0;
  padding: 0.4em;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  background: #17325b;
  color: rgb(255, 255, 255);
  animation: pulse 1s ease-in infinite;
  z-index: 10000;
}
@keyframes pulse {
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}
@keyframes pulse {
  50% {
    transform: scale(0.8);
  }
}
@keyframes pulse {
  50% {
    transform: scale(1.2);
  }
}

/* ********************* */

.app {
  /* position: relative; */
  position: absolute;
  width: 400px;
  display: flex;
  min-height: 100vh;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  transition: 0.2s linear;
  left: -400px;
}

.app.is-active {
  left: 0;
  /* left: calc(-100vw + 500px); */
}

.menu-toggle {
  position: fixed;
  top: 60px;
  left: -145px;
  width: 190px;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(188, 188, 188);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 0 20px;
  z-index: 10000;
  transition: 0.2s linear;
}

.menu-toggle:hover {
  position: fixed;
  top: 60px;
  left: -1px;
  background-color: rgb(53, 53, 53);
}

.hamburger {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 25px;
  color: #fff;
  z-index: 10000;
  cursor: pointer;
}

.sidebar {
  position: absolute;
  left: 0px;
  /* left: -400px; */
  width: 100%;
  min-height: 100vh;
  max-width: 400px;
  background-color: #fff;
  transition: 0.2s linear;
  z-index: 10001;
}

/* .sidebar.is-active {
    left: 0;
  } */

.course-list-container {
  padding: 0 30px 30px 30px;
}

.sidebar-header {
  position: sticky;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 12;
  padding: 0 20px;
  box-shadow: 0px 1px 2px #888888;
}

.sidebar-header > .hamburger {
  color: black;
  font-size: 20px;
  right: 15px;
}

.twitter-share-button {
  position: fixed;
  top: 250px;
  right: 20px;
  width: 155px;
  padding-left: 4px !important;
  padding-right: 10px !important;
  background-color: rgb(0, 172, 237) !important;
  color: #fff !important;
  border-radius: 18px;
  z-index: 10001;
}

.progressNotificationBar {
  position: fixed;
  top: 40px;
  right: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  z-index: 10001;
}

.joinCommunity {
  position: fixed;
  top: 295px;
  right: 20px;
  width: 155px;
  text-align: center;
  padding-left: 4px !important;
  padding-right: 10px !important;
  background-color: #0088cc !important;
  color: #fff !important;
  border-radius: 18px;
  z-index: 10001;
  cursor: pointer;
}

.courseProgressBar {
  height: 10px !important;
  width: 410px;
  background: #e6e6e6;
}

.courseProgressBar > * {
  background-color: #4c308e !important;
}

.circularProgress {
  width: 60px;
  height: 60px;
  color: "#fff";
}

.circularProgressText {
  display: flex;
  color: #fff;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0;
}
