* {
  margin: 0;
  padding: 0;
}
.proIcon {
  background: #f9c80e;
  border-radius: 4px;
  color: #2c3131;
  font-size: 12px;
  padding: 3px 20px;
  margin-right: 5px;
  cursor: pointer;
}
.perksWrapper {
  display: flex;
  margin: 0.5rem 0 0.5rem 1.5rem;
  height: 57px;
  width: 162px;
}
.statsContainer {
  /* width: 350px; */
  padding: 0.2rem 1rem;
  background: #1f2c42;
  border: 2px dashed var(--yellow);
  border-radius: 6px;
  /* padding: 5px 5px; */
  color: #fff;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  /* line-height: 140%; */
  /* identical to box height, or 20px */

  color: #ffffff;
  /* margin-top: 1rem; */
}

.container1 {
  /* padding: 3rem; */
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #ffffff;
  /* margin-top: 3rem; */
}
.cardBlock {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  /* margin-top: 3rem; */
  justify-content: flex-start;
  /* margin: 3rem 0 0 0; */
}
.cards {
  /* width: 380px; */
  /* width: 32%; */
  /* min-width: 330px; */
  width: 350px;
  min-height: 270px;
  /* height: 300px; */
  /* height: 270px; */
  /* margin: 0 2rem 0 0; */
  /* margin-top: 3rem;
  margin-bottom: 3rem; */
  /* margin: 1rem; */
  margin: 10px;
  /* align-self: stretch; */
}

.cardHeading {
  padding-bottom: 0.5rem;
  color: #ffffff;
  font-size: 26px;
  line-height: 31.2px;
}
.yellowBorder {
  border: 2px dashed #f9c80c;
}
.cardImg {
  /* position: relative; */
  /* background-color: #1f2c42; */
  /* padding: 24px 24px 44px 24px;
  border-radius: 6px; */
  /* border: 2px dashed var(--yellow); */
  /* height: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cardBaseImage {
  height: 186px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* max-width: 400px;
  height: auto; */
  object-fit: cover;
}

.cadrProgess {
  position: relative;
  background-color: #1f2c42;
  width: 100%;
  /* width: 380px; */
  padding: 24px;
  height: 270px;
  border-radius: 6px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
}

.progressTab {
  display: flex;
  justify-content: space-between;
  /* margin: 1.9rem 0; */
}
.progressTab .circularProgress {
  width: 147.75px;
  height: 147.75px;
}

.circularProgressImage {
  width: 80px;
  margin-top: -5px;
  border-radius: 10%;
}

.circularProgressText {
  font-size: 20px;
  margin-top: -5px;
  color: #fff;
}

.button {
  width: 125px;
  height: 35px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background-color: #1f2c42;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 15px;
  overflow: hidden;
  overflow-y: scroll;
}

.rangeHead {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #909090;
  padding-bottom: -15px;
}

.playIcon {
  position: absolute;
  color: #f9c80e;
  font-size: 3rem;
  cursor: pointer;
  left: 42%;
  bottom: 50%;
}
.cardText {
  /* position: relative; */
  bottom: 24px;
  padding-top: 1rem;
  color: #ffffff;
  font-size: 14px;
  /* font-family: 'Open Sans', sans-serif; */
  line-height: 19.2px;
  display: flex;
  justify-content: space-between;
}

/* .scrollContainer {
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 60%;
  overflow: hidden;
} */
.left {
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 60%;
  /* animation properties */
  /* -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite; */
}

.cardText > .right {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.textSpace {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cardBody {
  position: relative;
  background-color: #1f2c42;
  width: 100%;
  height: 100%;
  /* width: 380px; */
  /* height: 270px; */
  padding: 20px;
  border-radius: 6px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  /* margin: 0 2rem 0 0; */
  /* dis */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* font-size: 2rem; */
  flex-wrap: wrap;
}
.allIcons {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}
.allIcons p {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  width: 90px;
}

.allIcons .icons {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-bottom: 10px;
  background-color: #8437f9;
}

.iconCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mAuto {
  cursor: pointer;
  margin-left: auto;
}
/*  */

.LeaningPlans {
  display: flex;
}
.blocks {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.myLearningPaths {
  margin-top: 2.5rem;
  /* width: 203px; */
  height: 31px;
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  text-align: left;
  color: #ffffff;
}
.box {
  padding: 1rem;
  width: 1090px;
  height: 110px;
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
}
.cardHeading2 {
  width: 107px;
  height: 20px;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #909090;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.iconsandTitle {
  display: flex;
}
.contentHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}
.p {
  display: flex;
  justify-content: space-around;
}
.progress {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}

.progressBar {
  display: flex;
  flex-direction: column;
}

.progressBar,
.lable {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #a9a9a9;
}

progress::-webkit-progress-bar {
  background-color: #eeeeee;
}

a {
  text-decoration: none;
}

.wrapper {
  position: relative;
  display: flex;
  margin: 0.5rem 0 0.5rem 1.5rem;
  height: 57px;
  width: 162px;
}

.wrapper .icon {
  position: absolute;
  height: 57px;
  width: 57px;
  display: flex;
  border-radius: 50%;
}

.paidCardTop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.paidCardHeading {
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 14px;
  line-height: 120%;
  width: 40%;
  color: #ffffff;
}

.paidCardProgressBar {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
}

.paidCardProgressBar > p {
  /* font-family: 'Open Sans', sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
}

.courseProgressBar {
  margin-top: 5px !important;
  height: 5px !important;
  background: #e6e6e6;
}

.courseProgressBar > * {
  background-color: #f9c80e !important;
}

@media screen and (max-width: 1400px) {
  .currentCourseProgress {
    padding-left: 20px;
  }
}

#scroll-text {
  text-align: right;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@media screen and (max-width: 450px) {
  .container1 {
    padding: 28px 15px 48px 15px;
  }

  .cardBlock {
    justify-content: center !important;
  }

  .cards {
    min-width: 300px;
  }

  .progressTab .circularProgress {
    width: 100px;
    height: 100px;
    margin-top: 10px;
  }

  .circularProgressImage {
    width: 60px !important;
    margin-top: -45px;
  }

  .circularProgressText {
    font-size: 14px;
    margin-top: -5px;
    color: #fff;
  }

  .cardBody {
    min-height: 338px;
    /* padding-bottom: 40px; */
  }

  .myLearningPaths {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 376px) {
  .container1 {
    padding: 28px 5px 48px 5px;
  }

  .cards {
    min-width: 280px;
  }

  /* .cardImg {
    padding: 16px;
  } */

  .cadrProgess {
    padding: 16px;
  }

  .cardBody {
    min-height: 320px;
    padding: 16px 16px 16px 16px;
  }

  .left {
    bottom: 16px;
    left: 16px;
  }

  .cardText > .right {
    right: 16px;
    bottom: 16px;
  }
}
