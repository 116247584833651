.container1 {
  /* padding: 3rem; */
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
}
.title {
  color: #ffffff;
  margin-top: 3rem;
}
.cardBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* margin-top: 3rem; */
  justify-content: space-between;
  /* margin: 3rem 0 0 0; */
}
.cards {
  /* width: 380px; */
  width: 32%;
  min-width: 300px;
  /* height: 300px; */
  height: 163px;
  /* margin: 0 2rem 0 0; */
  margin-top: 3rem;
}
.cardHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  /* or 24px */

  color: #ffffff;
}
.cardImg {
  position: relative;
  background-color: #1f2c42;
  padding: 24px 24px 44px 24px;
  border-radius: 6px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  /* height: auto; */
}

.cardBaseImage {
  height: 186px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* max-width: 400px;
    height: auto; */
  object-fit: cover;
}

.cadrProgess {
  position: relative;
  background-color: #1f2c42;
  width: 100%;
  /* width: 380px; */
  padding: 24px;
  height: 270px;
  border-radius: 6px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
}

.progressTab {
  display: flex;
  justify-content: space-between;
  /* margin: 1.9rem 0; */
}

.cardText {
  /* position: relative; */
  bottom: 24px;
  padding-top: 1rem;
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 19.2px;
  display: flex;
  justify-content: space-between;
}

.cardText > .left {
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.cardText > .right {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
.ringButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ringActiveBtn {
  border: none;
  padding: 12px;
  background: #f9c80e;
}

.cardBody {
  position: relative;
  background-color: #1f2c42;
  width: 100%;
  /* width: 380px; */
  min-height: 163px;
  padding: 24px;
  border-radius: 6px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  /* margin: 0 2rem 0 0; */
  /* dis */
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* font-size: 2rem; */
  flex-wrap: wrap;
}
.allIcons {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}

/*  */

.p {
  display: flex;
  justify-content: space-around;
}

a {
  text-decoration: none;
}

.wrapper {
  position: relative !important;
  display: flex;
  /* margin: 0.5rem 0 0.5rem 1.5rem; */
  height: 57px;
  width: 170px;
}

.tutor1 {
  position: absolute !important;
  right: 93px;
  top: 0;
}

.tutor2 {
  position: absolute !important;
  right: 62px;
  top: 0;
}

.tutor3 {
  position: absolute !important;
  right: 31px;
  top: 0;
}

.tutor4 {
  position: absolute !important;
  right: 0px;
  top: 0;
}

.helpWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;

  width: 127px;
  height: 29px;

  background: #8437f9;
  border-radius: 18px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto 0;
}

.helpWrapper > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.wrapper .icon {
  position: absolute;
  height: 44px;
  width: 44px;
  display: flex;
  border-radius: 50%;
}

.cardTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.paidCardProgressBar {
  width: 100%;
  /* margin-bottom: 30px; */
}

.paidCardProgressBar > p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
}

.courseProgressBar {
  margin-top: 5px !important;
  height: 5px !important;
  background: #e6e6e6;
}

.courseProgressBar > * {
  background-color: #f9c80e !important;
}

/* ******** */

.tutorDropdown {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  letter-spacing: -0.01em;

  color: #909090;
}

.tableHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  color: #fff;
}

.tableContainer {
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px !important;
  box-sizing: border-box;
  padding: 2px 23px;
}

.tableHead {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: #909090;
  /* height: 60px; */
}

.tableHeadData {
  /* height: 60px; */
  padding: 20px 8px 20px 0 !important;
}

.tableBodyData {
  padding: 25px 8px 25px 0 !important;
}

.tabelBody {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.tableRow {
  border-top: 2px dashed rgba(255, 255, 255, 0.1) !important;
  height: 70px;
}

.statusBtn {
  background-color: #8437f9;
  padding: 7px 10px;
  border-radius: 4px;
}

@media screen and (max-width: 1430px) {
  .currentCourseProgress {
    padding-left: 10px;
  }

  .cardBody {
    height: 200px;
  }

  .cards {
    height: 200px;
  }
}

@media screen and (max-width: 450px) {
  .container1 {
    padding: 28px 15px 48px 15px;
  }

  .cardBlock {
    justify-content: center !important;
  }

  .helpWrapper {
    padding: 6px 10px;
  }

  .helpWrapper > p {
    font-size: 11px;
  }

  .cards {
    min-width: 300px;
    height: 174px;
  }

  .cards:nth-child(2) {
    height: 160px;
  }

  .cardBody {
    padding-bottom: 70px;
    height: fit-content;
    min-height: 150px !important;
  }

  .cardHeading {
    font-size: 17px;
  }

  .tableHeading {
    font-size: 22px;
  }

  .tutor1 {
    right: 75px;
  }

  .tutor2 {
    right: 50px;
  }

  .tutor3 {
    right: 25px;
  }

  .tutor4 {
    right: 0px;
  }
}

@media screen and (max-width: 391px) {
  .helpWrapper {
    padding: 6px 9px;
  }

  .helpWrapper > p {
    font-size: 10px;
  }

  .howcanhelp {
    font-size: 10px !important;
  }
  .container1 {
    padding: 28px 10px 48px 10px;
  }

  .cards {
    min-width: 290px;
  }
}

@media screen and (max-width: 376px) {
  .howcanhelp {
    font-size: 10px !important;
  }
  .container1 {
    padding: 28px 5px 48px 5px;
  }

  .cards {
    min-width: 280px;
  }

  .cardImg {
    padding: 16px;
  }

  .left {
    bottom: 16px;
    left: 16px;
  }

  .cardText > .right {
    right: 16px;
    bottom: 16px;
  }
}

.howcanhelp {
  font-size: 10px !important;
}
