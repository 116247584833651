.footer{
  background: hsl(217, 58%, 18%);
padding: 70px 300px 50px;
}

.rightSec {
  width: 70vw;
}

.heading_width {
  width: 158px;
  margin-top: 10px;
}
.link_width {
  display: flex;
  justify-content: space-between;
  width: 500px;;
}
.right_icon {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.icon_margin {
  margin-right: 10px;
}
.de_flex{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .footer{
  padding: 70px 40px 50px;
  }
  
    }
  
  
  @media screen and (max-width: 768px) {
   
    .de_flex{
      display: flex;
      flex-direction: column;
    }
    .link_width {
      display: flex;
      justify-content: space-between;
      width: 300px;
      flex-direction: column;
      
    }
    .footer_marging {
      margin-top: 15px;
    }
  
  }