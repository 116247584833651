.qr-videoSidebar {
  position: absolute;
  bottom: 8vh;
  height: 20%;
  color: white;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0)); */
}

.qr-videoSidebar p {
  color: white;
  font-weight: 600;
}

.qr-videoSidebar__button {
  padding: 0.6rem;
  margin-bottom: 0.6rem;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .qr-videoSidebar {
    position: absolute;
    top: 40%;
    color: white;
    right: 0;
  }
}

/* .videoSidebar__button:hover {
  cursor: pointer;
  background-color: #8556f8;
  opacity: 1;
} */
