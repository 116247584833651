.deferred-student-yellow-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deferred-student-cards {
    display: flex;
    gap: 5px;
}

.deferred-student-card {
    width: 50px;
    height: 80px;
    border: 1px solid #00000040;
    display: inline-block;
    transform: rotate(-10deg);
}

.yellow-card {
    background-color: #fbd96b;
    border: 1px solid #fbd96b;
}

.empty-card {
    background-color: white;
}

.deferred-student-card:nth-child(1) {
    transform: rotate(-15deg);
}

.deferred-student-card:nth-child(2) {
    transform: rotate(-7deg);
}

.deferred-student-card:nth-child(3) {
    transform: rotate(0deg);
}

.deferred-student-card:nth-child(4) {
    transform: rotate(7deg);
}

.deferred-student-card:nth-child(5) {
    transform: rotate(15deg);
}