* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.dashboardContainer {
  display: flex;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  z-index: 9999;
}
main {
  width: 100vw;
  /* padding: 20px; */
}
.dashboardSidebar {
  position: fixed;
  margin: auto;
  /* padding: 40px; */
  background: #fff;
  color: #8f8f8f;
  height: 100vh;
  width: 240px;
  transition: all 0.5s;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 2;
}
.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px 10px;
}
.logo {
  /* font-size: 30px; */
}
.navbarLogo {
  /* justify-self: start; */
  /* margin-left: 20px; */
  /* width: 62px; */
  position: relative;
  margin: 5px auto;
  cursor: pointer;
}

.collapsIconLeft {
  position: absolute;
  font-size: 34px;
  right: -60px;
  top: 12%;
  color: #0d1e3a;
  cursor: pointer;
}

.collapsIconRight {
  margin-top: 24px;
  font-size: 34px;
  color: #0d1e3a;
  cursor: pointer;
}

.zaioLogo {
  width: 90px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  position: relative;
  display: flex;
  color: #8f8f8f;
  margin: 8px 0;
  padding: 10px 35px;
  gap: 15px;
  font-size: 16px;
  transition: all 0.5s;
}

.winAPhone {
  margin: 0;
  font-weight: 500;
}

.winAPhone {
}

.menuHeading {
  display: flex;
  flex-wrap: wrap-reverse;
  color: #8f8f8f;
  margin-bottom: 8px;

  padding: 10px 35px;
  gap: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  justify-content: space-between;
}

.link:hover {
  background: #8437f9;
  color: #fff;
  transition: all 0.5s;
}

/* Sidebar.css */

.active {
  background: #8437f9;
  color: #fff;
}
.icon,
.link_text {
  text-align: center;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.icon .wallet {
  width: 50px;
  height: 50px;
}

.link_text > .arrow {
  display: none;
}

.active > .link_text > .arrowActive {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
  font-weight: normal;
}

.teligramBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  border-bottom-right-radius: 15px;
  background-color: #0088cc;
  color: #fff;
  padding: 10px 35px;
  gap: 15px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .dashboardSidebar {
    display: none;
  }
  main {
    padding-left: 0px;
  }
}
