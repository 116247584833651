.index {
  position: absolute;
  font-size: 22px;
  color: white;
  background-color: rgb(40, 156, 40);
  border: 4px solid rgb(40, 156, 40);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
  top: -15px;
  left: -15px;
  margin: auto;
}
.container_wrapper {
  padding: 10px;
  width: fit-content;
}
.container {
  margin: auto;
  width: 100%;
  max-width: 390px;
  min-width: 250px;
  border-radius: 8px;
  height: 100%;
  min-height: 300px;
  -webkit-box-shadow: 0 5px 6px -6px black;
  -moz-box-shadow: 0 5px 6px -6px black;
  box-shadow: 0 5px 6px -6px black;
  box-sizing: border-box;
}
.container_body {
  padding: 12px;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.author_image {
  bottom: 15px;
  right: 15px;
  position: absolute;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  font-weight: bold;
  color: hsl(213deg 31% 37%);
  line-height: 23px;
}

.teacher {
  bottom: 28px;
  font-size: 12px;
  color: hsla(220, 25.71%, 13.73%, 100%);
}

.bold {
  font-weight: bold;
}

.level {
  font-size: 12px;
  color: hsla(220, 25.71%, 13.73%, 100%);
  bottom: 8px;
}

.discount {
  font-size: 16px;
  /* or 100% */

  letter-spacing: 0.625px;

  color: #ff0000;
}
.op {
  font-size: 20px;
  text-align: end;
  letter-spacing: 0.625px;
  text-decoration-line: line-through;
  color: #989898;
  margin-bottom: -8px;
}

.price {
  text-align: end;
  font-style: normal;
  font-weight: bold;
  /* identical to box height, or 50% */
  font-size: 26px;
  letter-spacing: 0.625px;
  color: #6e31f9;
}
.daysleft {
  text-align: end;
  font-size: 13px;
  /* identical to box height, or 100% */

  letter-spacing: 0.625px;

  color: #989898;
}
