header {
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: transparent;
  height: 7vh;
  width: 100%;
  max-width: 540px;
  padding: 1rem;
  /* display: none; */
}
.centre-items {
  display: flex;
  justify-content: center !important;
}
.header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  padding: 1rem;
  /* marg */
}
.stats {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.course-progress {
  width: 50%;
  height: 100%;
}

.circle {
  height: 60px;
  width: 60px;
  padding: 0;
  position: relative;
  margin-left: 10px;
}
.score {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  height: 100%;
  animation: score 2s ease-in-out
}
.icons {
  color: white;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

@keyframes score {
  0% {
    scale: 1;
  }
  /* 25% {
    scale: 1.5;
  } */
  50% {
    scale: 3;
  }
  /* 75% {
    scale: 1.5;
  } */
  100% {
    scale: 1;
  }
}

@media only screen and (max-width: 600px) {
  header {
    display: block;
  }
}
