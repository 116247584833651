.video {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  top: 0;
}
.confetti {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000000;
}
.test {
  position: relative;
  top: 50%;
  left: 0;
  color: yellow;
}
.video__player {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
  height: 100%;
}
/* video[poster] {
  object-fit: cover;
}  */
/* or object-fit:fill */

/* video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
} */


.video input[type="range"] {
  /* same setting as videocss footer */
  -webkit-appearance: none;
  /* margin-right: 15px; */
  /* margin-left: 40px; */
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.6);
  background-image: linear-gradient(white, white);
  background-repeat: no-repeat;
  /* transition-property: background-size;
  transition-duration: 0.5s;
  transition-timing-function: linear; */
  position: absolute;
  bottom: 7vh;
  left: 0;
  cursor: pointer;
  z-index: 2;
  /* position: fixed; */
}

/* Input Thumb */
.video input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: white;
  /* z-index: 1; */
  /* transition-property: background-size;
  transition-duration: 0.5s;
  transition-timing-function: linear; */
}

.video input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1px;
  width: 1px;
  background-color: #ffc107;
}

.video input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 1px;
  width: 1px;
  background-color: #ffc107;
}

/* Input Track */
.video input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.video input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.video input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.play-icon {
  position: absolute;
  top: calc(50% - 10vh);
  left: 49%;
  z-index: 100001;
  scale: 2;

  animation-name: example;
  animation-duration: 0.5s;
}

.transparent {
  opacity: 10%;
}

.scroll-icon {
  transform: rotate(-45deg);
  position: absolute;
  bottom: 15%;
  right: 15%;
  z-index: 100001;
  scale: 2;

  animation: scroll 2s infinite;
}

@keyframes example {
  0% {
    scale: 2;
  }

  100% {
    scale: 3;
  }
}

@keyframes scroll {
  from {
    bottom: 15%;
  }
  to {
    bottom: 35%;
  }
}

@media only screen and (max-width: 600px) {
  .video__player {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -7vh;
  }
  input[type="range"] {
    bottom: 14vh;
  }
}
