.teampath {
    position:  relative;
}

.teampath-accordion {
    position: relative;
}

.ringpartner {
    position: absolute;
    top: -25px;
    right: -15px;
    font-size: 2.5rem;
    z-index: 1;
    font-style: italic;
    cursor: pointer;
    color: var(--yellow);
}
