@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url(//db.onlinewebfonts.com/c/0406c39d71726a4efe1134bf8e3feb71?family=Apercu+Pro); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@font-face {
  font-family: "Apercu Pro";
  src: local("Apercu Pro"),
    url("./assets/fonts/apercu-pro/apercu_bold_pro.otf") format("truetype");
  font-weight: bold;
}
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --yellow: #f9c80c;
  --purple: #8437f9;
  --grey: #909090;
  --blue: #0d1e3a;
  --green: #00d395;
  --red: #ff8489;
}
html,
body {
  padding: 0 0px;
  margin: 0;
  font-family: sans-serif, "Apercu Pro", "Open Sans";
  background-color: #0d1e3a !important;
}
* {
  box-sizing: border-box;
}
p.installment_text {
  font-size: 15px;
}
.install_check {
  position: absolute;
  left: 13px;
  top: 7px;
  width: 20px;
  height: 20px;
  border-radius: 0%;
}
.installments_box .form-control {
  height: auto;
  min-height: calc(1.5em + 0.75rem + 2px);
}
.addon_check {
  margin-right: 11px;
  width: 18px;
  height: 18px;
}
.addon_text {
  cursor: pointer;
}

.btn-main {
  color: #fff !important;
  background-color: #8437f9 !important;
  border-color: #802ef9 !important;
}
.font-color-purple {
  color: var(--purple);
}

.link-main {
  color: #8437f9 !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-16 {
  font-size: 16px;
}
.heading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */
  color: #ffffff;
}
.big-heading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 120%;
  /* identical to box height, or 53px */
  color: #ffffff;
}
.smalltext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  color: #ffffff;
}

.apercuProText {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* identical to box height, or 24px */
}

.mediumText {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  /* or 38px */
}

.mediumBlackText {
  /* H3 */
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  /* or 31px */
  color: #0d1e3a;
}
.cursor {
  cursor: pointer !important;
}

.normalText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* or 19px */
}

.normalTextBold {
  font-weight: 600;
  color: #0d1e3a;
}

.normalTextColorBlack {
  color: #2c3131;
}

.normalDarkBlue {
  color: #2c3131;
}

.normalTextColorGrey {
  color: #8f8f8f;
}

.font_16 {
  font-size: 16px;
}

.font_14 {
  font-size: 14px;
}

.font_12 {
  font-size: 12px;
}

.font_20 {
  font-size: 20px;
}
.navbar-light .navbar-toggler {
  background-color: #f9c80c !important;
  color: white;
  border-radius: 50px;
  padding: 15px;
}

.offcanvas-body > div {
  align-items: center;
}
