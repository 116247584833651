.text {
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 90%;
  /* or 67px */
  text-align: center;
  color: #ffffff;
}
.border {
  border-bottom: #dfdfdc 1px solid;
  margin-right: 20px;
  margin-bottom: 15px;
  padding-top: 10px;
}
.lpTop {
  flex: 1 0 0;
  padding-bottom: 2S0px;
}
.elipse {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: -7px;
  border: #fff 0.5px solid;
  background-color: rgb(247, 245, 245);
  background-size: cover;
  background-position: center;
}

.elipse p {
  margin-top: 5px;
  margin-left: 5px;
  font-size: 11px;
}
.card {
  background-color: #8437f9;
  color: #fff !important;
  overflow: hidden;
  border-radius: 10px;
  padding: 0 0 50px 30px;
  & .free {
    color: #fff !important;
  }
  & .heading {
    color: #fff !important;
  }
  & .textpara {
    color: #fff !important;
  }
  & .imgbox {
    background-color: #fff !important;
  }
}

.card2 {
  background-color: #ffffff;
  color: #8d959c;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
  padding: 0 0 50px 30px;
  & .heading,
  .price {
    color: #0d1e3a !important;
  }
}
.imgbox {
  width: 100px;
  height: 100px;
  background: #f9c80e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.imgbox2 {
  width: 100px;
  height: 100px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.free {
  display: flex;
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* identical to box height, or 20px */
  text-transform: uppercase;
  color: #0d1e3a;
}

.courseNbr {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.heading {
  text-align: left;
  /* H6 */
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 130%;
  margin-bottom: -10px;
  margin-top: 15px;
}
.price {
  text-align: left;
  /* H6 */
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* H4 */
  color: #ffffff;
}

.listBox {
  padding: 25px;
  text-align: left;
}
.margright {
  margin-right: 10px !important;
}

@media screen and (max-width: 450px) {
  .iconsandTitle {
    display: none !important;
  }

  .heading {
    margin-top: 10px;
    line-height: 110%;
    font-size: 22px;
  }

  .free {
    margin-top: -65px !important;
  }
}
