.bootcampModeContainer {
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .bootcampModeContainer {
    padding: 28px 15px 48px 15px;
  }
}
