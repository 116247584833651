.test {
  font-family: 'Apercu Pro';
  font-size: 52px;
  margin-left: 10px;
  max-width: 800px;
  color: #f8f1eb;
  letter-spacing: 3px;
  font-weight: 700;
  line-height: 70px;
  position: absolute;
  bottom: 10px;
  text-align: left;
  z-index: 2;
}
.discount {
  text-align: start;
  font-size: 16px;
  /* or 100% */

  letter-spacing: 0.625px;
  width: fit-content;
  color: #ff0000;
}
.op {
  font-size: 20px;
  text-align: start;
  letter-spacing: 0.625px;
  text-decoration-line: line-through;
  color: #989898;
  margin-bottom: 0px;
}

.price {
  width: fit-content;
  text-align: start;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.625px;
  margin-right: 5px;
  color: #6e31f9;
  margin-bottom: -3px;
}
.daysleft {
  text-align: start;
  font-size: 13px;
  /* identical to box height, or 100% */

  letter-spacing: 0.625px;

  color: #ff0000;
  margin-bottom: 10px;
}

.bg_grey {
  background-color: #F8F8F9 !important;
}

.form_label {
  padding: 5px;
}

.sep {
  display: flex;
  flex-direction: row;
}
.sep p {
  color: #8f8f8f;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.sep:before,
.sep:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #cbc9c9;
  margin: auto;
}

.form_select {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #8437f9;
}

.imgbox {
  width: 37px;
  height: 37px;
  background: #8437f9;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  color: #fff;
  justify-content: center;
  align-items: flex-end;
}

.imgboxText {
  align-self: center;
  font-weight: 800;
  margin-top: 10px;
}

.grey {
  color: #8f8f8f;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.containerAcc {
  background-color: #8437f9;
  padding: 120px 0px;
}
.accordianBg {
  background-color: transparent !important;
  color: #fff;
}
