.course-container {
  position: relative;
  width: 290px !important;
  min-width: 290px;
  min-height: 270px;
  background: #1f2c42;
  /* border: 1px dashed rgba(255, 255, 255, 0.1); */

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between ;
  /* padding: 20px; */
  margin: 10px;
}

.course-container:hover {
  cursor: pointer;
  /* border: 0.5px solid var(--yellow); */
  /* transform: scale(1.2);
  margin: 0 40px; */
  /* z-index: 9999; */
}

.course-small-info {
  display: inline-block;
  opacity: 0.5;
  margin-top: 10px;
}

.course-thumbnail {
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
}
.yellow-course-icon {
  z-index: 2;
  position: absolute;
  color: #f9c80e;
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.2s linear ease-in-out;
  /* left: 42%;
    bottom: 50%; */
  top: 50%;
  right: calc(50% - 1.5rem);
  /* transform: translateY(-50%); */
}
.pulse {
  -webkit-animation: pulse 1.5s infinite;
  transform: scale(1);
  animation: pulse 1.5s infinite;
}
.locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.AccordionprogressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.percentageIcon {
  position: absolute;
  right: 0;
}

.courseProgressBar {
  /* margin-top: 5px !important; */
  height: 5px !important;
  background: #e6e6e6;
  width: 100%;
}
.courseProgressBar > * {
  background-color: #f9c80e !important;
}

.course-progress {
  width: 100%;
}
.course-details-info {
  padding: 10px;
  z-index: 2;
}

.course-modal span {
  display: inline-block;
  display: flex;
  align-items: center;
}

.course-note {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 2;
  padding: 0 10px;
  background-color: var(--yellow);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: #1f2c42;
  opacity: 0;
  margin-right: -5px;
  display: flex;
  justify-content: center;
}
.green-course-icon {
  color: var(--green);
}
.completed {
  background-color: var(--green);
  color: white;
}
.purple-bg {
  background-color: var(--purple);
  color: white;
}

.course-percentage-progress-show {
  opacity: 1;
  transition: all 0.2s linear ease-in-out;
  margin: 0;
}

.custom-btn {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 0;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s linear ease-in-out;
}

.success-btn {
  background-color: var(--green);
}
.danger-btn {
  background-color: var(--red);
}