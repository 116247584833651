/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  /* background-color: #eee; */
  /* color: #444; */
  position: relative;
  cursor: pointer;
  padding: 18px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  /* background-color: #ccc; */
}

/* Style the accordion content title */
.accordion__title {
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 600;
  font-size: 14px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  height: 10px;
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #8437f9;
}
/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: white;
  /* background-color: white; */
  overflow: hidden;
  /* overflow-x: scroll; */
  transition: max-height 0.6s ease;
  padding-bottom: 5px;
  margin: 1rem 0;
}

.accordianContenttxts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordianContenttxts .lock {
  padding: 1rem;
  width: 50px;
  margin: 0.1rem;
  font-size: 2rem;
  border-radius: 2rem;
  background-color: #8437f9;
  color: #ffffff;
}
.accordianContenttxts span {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  color: #ffffff;
}
.accordianContenttxts .span2 {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #ffffff;
}
/* Style the accordion content text */
.accordion__text {
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 400;
  font-size: 14px;
  padding: 18px 6px 15px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  /* min-height: 270px; */
}

.accordianContent {
  /* position: relative; */
  width: 290px !important;
  min-height: 290px;
  /* min-height: 270px; */
  background: #1f2c42;
  border: 1px dashed rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  /* padding-bottom: 200px; */
}

.acordiaTitle {
  width: 100%;
  min-height: 33px;
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  padding-right: 35px;
  align-items: center;
}

.acordiaContent {
  width: 100%;
  position: relative;
  padding-bottom: 190px;
  margin-top: auto;
}

.playIcon {
  position: absolute;
  color: #f9c80e;
  font-size: 3rem;
  cursor: pointer;
  /* left: 42%;
  bottom: 50%; */
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.acordiaTitle div {
  display: inline-block;
  /* width: 90%; */
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
}

.acordiaTitle .playIcon {
  /* margin-left: 1rem; */
  color: #f9c80e;
  font-size: 2rem;
  cursor: pointer;
}
.acordionImg {
  position: absolute;
  bottom: 65px;
  left: 0;
  padding: 1rem;
  height: 120px;
}
.AccordionprogressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AccordionprogressBar progress {
  width: 100%;
  /* color: red; */
}

/* ****************************************** */

.box {
  padding: 1rem;
  width: 1090px;
  height: 110px;
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
}

.iconsandTitle {
  display: flex;
}

.contentTitle {
  position: relative;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #909090;
}

.contentHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}

.progressBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 140px;
}

.mobileViewProgressBar {
  display: none;
}

.progressBar,
.lable {
  position: relative;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #a9a9a9;
}

.percentageIcon {
  position: absolute;
  right: 0;
}

.p {
  display: flex;
  justify-content: space-around;
}

.wrapper {
  display: flex;
  margin: 0.5rem 0 0.5rem 1.5rem;
}

.wrapper .icon {
  height: 30px;
  width: 30px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: -7px;
}

.html {
  background-color: #e44d26;
  z-index: 0;
}

.react {
  background-color: #18bcee;
  z-index: 1;
}

.plusNbr {
  background-color: #f4f4f8;
  z-index: 1;
  font-size: 10px;
  color: #0d1e3a;
}

.css {
  background-color: #8ab4f8;
  z-index: 0;
}

.icon i {
  font-size: 30px;
  color: white;
}

.courseProgressBar {
  margin-top: 5px !important;
  height: 5px !important;
  background: #e6e6e6;
  width: 100%;
}

.courseProgressBar > * {
  background-color: #f9c80e !important;
}

/* .cssProgress {
  width: 100%;
  margin-bottom: 20px;
}
  .progress1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
  }

  .cssProgress-bar {
    display: block;
    float: left;
    width: 0%;
    height: 100%;
    background-color: red;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.8s ease-in-out;
  }

  .cssProgress-label {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.7em;
    text-align: center;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
  } */

@media screen and (max-width: 1200px) {
  .box2 {
    height: 130px;
  }
  .progressBar2 {
    display: none;
  }

  .mobileViewProgressBar2 {
    display: flex;
    margin-top: 5px;
  }
}

@media screen and (max-width: 450px) {
  .box {
    height: 130px;
  }

  .progressBar {
    display: none;
  }

  .mobileViewProgressBar {
    display: flex;
    margin-top: 5px;
  }

  .contentHeading {
    font-size: 14px;
  }

  .contentTitle {
    font-size: 12px;
  }
}
