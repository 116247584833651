.checklist {
    /* font-size: 1rem; */
    /* background: red; */
}
.checklist-stepper {
    width: 100%;
}
.checklist-item {
    /* padding: 0.5rem; */
}


.checklist-text {
    /* margin-left: 1rem; */
    font-style: italic;
}
.inactive-text {
    color: var(--grey);
}