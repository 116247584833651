.quizContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.quizOption {
  border: none;
  outline: none;
  background-color: whitesmoke;
  color: black;
  font-weight: 900;
  font-size: 1rem;
  padding: 5px;
  border: whitesmoke 1px solid;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  margin: 0.2rem;
}
