$red-live: #ea2429;
$white: #fff;


.live-indicator-block{
  display: inline-block;
  margin: 0 5px;
  .live-indicator {
      background: #ea2429;
      color: $white;
      padding: 6px 7px;
      line-height: 1;
      border-radius: 6px;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      font-weight: bold;
      width: auto;
      display: flex;
      .blink {
        animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
        font-size: 10px;
        margin-right: 5px;
        vertical-align: baseline;
      }
  }
}

@keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0; }
  }