.navbar {
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.navItem {
  display: flex;
  flex-wrap: wrap;
}

.notificationLink {
  margin: '0 16px';
}

.navbarBrand {
  color: #fff !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.groupIconBox {
  position: relative;
  width: 45px;
  height: 45px;
  background: #f9c80e;
  border-radius: 100%;
  margin-left: 10px;
}
.upgradeBtn {
  border: none;
  background-color: #8437f9;
  margin-left: 10px;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
}

.bookConsultation {
  border: none;
  background-color: #f9c80e;
  margin-left: 10px;
  padding: 7px 5px;
  font-size: 12px;
  border-radius: 10px;
  font-weight: bold;
  color: black;
  height: 33px;
  margin-top: 7px;
}

.iconCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headingTwoDark {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 140%;
  color: #909090;
}

/* .dropdown {
  color: #909090;
  font-size: 14px;
  align-items: center;
  display: flex;
  margin-left: 8px;
} */
.dropdown {
  /* color: #909090;
  font-size: 14px;
  align-items: center;
  display: flex; */
  width: fit-content;
}

.dropdownItem {
  padding: 15px 30px;
  font-size: 14px;
  color: #909090;
  display: flex;
}

.navItemSubContainer {
  display: flex;
}

@media screen and (max-width: 992px) {
  /* .navItemContainer {
    text-align: center !important;
  } */

  .dropdown {
    margin: auto !important;
  }
  /* 
  .upgradeBtn,
  .groupIconBox {
    margin: 0 auto !important;
  } */
}

@media screen and (max-width: 750px) {
  /* .navFirstItem {
    width: 100% !important;
  }

  .groupIconBox {
    margin-right: auto !important;
  }

  .notificationLink {
    margin: '0';
  } */
}
