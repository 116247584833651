.job-card {
  width: 100%;
  cursor: pointer;

  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 20px 30px;
  color: #fff;

  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #ffffff;
  margin-top: 2rem;
}

.eligibility-note {
  width: 100%;
  cursor: pointer;
  background: #1f2c42;
  border: 2px dashed var(--yellow);
  border-radius: 6px;
  padding: 20px;
  color: #fff;

  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #ffffff;
  margin-top: 2rem;
}

.job-row {
  display: flex;
  align-items: center;
}

.eligible-icon {
  background: #80ff72;
  border-radius: 4px;
  color: #2c3131;
  font-size: 12px;
  padding: 3px 20px;
  margin-right: 5px;
  display: inline-block;
  margin: 3px;
  /* cursor: pointer; */
}
/* .eligible-icon:hover {
  background: var(--purple);
  color: white;
} */
.info-tag {
  background: var(--purple);
  color: white;
  font-size: 0.8rem;
  /* padding: 2rem; */
}
.not-eligible {
  background: red;
  color: white;
}
.yellow-tag {
  background: #f9c80e;
}
.purple-tag {
  background: var(--purple);
  color: white;
  cursor: pointer;
}

.yellow-tag:hover {
  background: var(--purple);
  cursor: pointer;
  color: white;
}
.tech-tag {
  background: var(--blue);
  color: white;
  padding: 1rem;
}
.tech-tag:hover {
  background: var(--purple);
  color: white;
}

.job-company-image {
  width: 5rem;
  border-radius: 50%;
  margin-right: 1rem;
  flex-grow: 1;
}
.job-summary {
  flex-grow: 3;
  flex-wrap: wrap;
}
.job-stacks {
  margin: 2rem;
  flex-grow: 1;
}

@media only screen and (max-width: 600px) {
  * {
    font-size: 13px;
  }
  .job-row {
    display: flex;
    flex-direction: column;
  }
  .job-summary {
    text-align: center;
    margin-bottom: 2rem;
  }
}
