.split {
  width: 100vw;
}
.ide-container {
  display: flex;
  background-color: #f1f1f1;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  position: relative;

  .code-editor-container {
    height: 100%;
    // width is calculated with code-editor-container-default
    // min-width: 10vw;
    display: flex;

    .ide-sidebar {
      height: 100%;
      background-color: #333;
      z-index: 1;
      padding: 10px 0;

      p {
        color: white;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        padding: 0;
        opacity: 50%;
        padding: 10px;
      }

      .project-file-btn {
        background: none;
        color: white;
        border: none;
        padding: 5px 0px;
        display: block;
        width: 100%;
        text-align: left;
        font-size: 16px;
        display: flex;
        align-items: center;

        .file-icon {
          margin-left: 10px;
          font-size: 20px;
        }
        code {
          color: white;
          margin-left: 5px;
        }

        span {
          margin-left: 5px;
        }
      }
      .selected {
        background: #1e1e1e;
      }

      span {
        display: block;
      }
    }

    code {
      color: white;
      margin-left: 5px;
    }

    .code-editor {
      // width: 70vw; widths calculated  with codes
      .code-editor__header {
        display: flex;
        justify-content: space-between;
      }
      .monaco-editor-container {
        height: 100%;
        width: 100%;
        z-index: 0;
      }
      .monaco-editor-header {
        background: #1e1e1e;
        width: 100%;
        padding: 10px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  // SCREEN MODES
  // .preview-mode-maximized {

  // }
  // .preview-mode-minimize
  // .preview-mode-closed
  // .preview-mode-default

  .code-editor-container-default {
    width: 100%;
  }
  .browser-window-default {
    width: 40%;
  }
  .code-editor-default {
    width: 85%;
  }
  .ide-sidebar-default {
    width: 15%;
  }

  .code-editor-container-split {
    // width: 50%;
    width: 100%;
  }
  .browser-window-split {
    width: 50%;
  }
  .code-editor-split {
    width: 100%;
  }
  .ide-sidebar-split {
    width: 0;
    display: none;
  }

  .code-editor-container-maximized {
    width: 0;
    display: none;
  }
  .browser-window-maximized {
    width: 100%;
  }
  .code-editor-maximized {
    width: 0;
  }
  .ide-sidebar-maximized {
    width: 0;
  }

  .code-editor-container-closed {
    width: 90%;
  }
  .browser-window-closed {
    width: 10%;
  }
  .code-editor-closed {
    width: 85%;
  }
  .ide-sidebar-closed {
    width: 15%;
  }

  .browser-window {
    height: 100%;
    position: relative;

    iframe {
      border: 0;
      width: 100%;
      height: 97vh;
    }
    .console {
      padding: 1rem;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #333;
      //   background-color: yellow;
      height: 55%;
      z-index: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;
    }
    .browser-window-tab-btn {
      color: white;
      padding: 10px;
      cursor: pointer;
      font-size: 14px;
    }
    .browser-window-tabs {
      display: flex;
      align-items: center;

      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #333;
      height: 4%;
      z-index: 1;
      width: 100%;
    }

    .preview-container-header {
      background: #e6e6e6;
      width: 100%;
      height: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .tools {
        margin: 5px;
        display: flex;
      }
      .preview-container-header-round {
        font-size: 1rem;
        cursor: pointer;
        margin: 0 2px;
        // background-color: var(--purple);
        // padding: 10px;
        // color: white;
      }
      .preview-container-header-round:hover {
        opacity: 50%;
      }
      .close {
        background-color: #ff622e;
      }
      .minimize {
        background-color: #ffa62e;
      }
      .maximize {
        background-color: #55cc43;
      }
    }
  }
}
