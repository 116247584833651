.waitingListBar {
  background-color: #8437F9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 300;
  text-align: center;
  width: 100%;
}
.waitingListBar:hover {
  background-color: #8537f9ab;
  color: rgba(255, 255, 255, 0.863);
}
.waitingListBar span {
  float: right;
  font-weight: bolder;
}
