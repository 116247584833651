/*===== sign up form ======= */
.socials-signup {
  white-space: nowrap;
}

.signup-title {
  font-weight: 700;
  font-size: 1.3rem;
}
.form-inline {
  display: flex;
  flex-direction: row;
}

.socials-btn {
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  border-color: #d9d9e2;
  padding: 0 5px;
  margin-right: 8px;
  width: 33%;
}

.socials-btn:hover {
  text-decoration: none;
  border-color: var(--secondary);
}

.signup-socials-img {
  width: 16px;
}

.signup-socials-text {
  vertical-align: middle;
  font-weight: bold;
  line-height: 44px;
  font-size: 0.75rem;
}

.signup-form {
  margin: 30px auto;
  max-width: 400px;
  min-height: 380px;
  margin-left: auto;
  margin-right: auto;

  padding: 20px 35px;
  border-radius: 0.65rem !important;
  box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
}

.question-form {
  margin: 30px;
  padding: 20px 35px;
  border-radius: 0.65rem !important;
  box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
}

// .forgot-password {
//   font-size: 0.75rem;

//   line-height: 1.2;
// }

.learn-button {
  width: 220px;
}

.business-button {
  width: 220px;
}

@media screen and (max-width: 480px) {
  .signup-form {
    padding: 20px;
  }
}

@media screen and (min-width: 480px) {
  .learn-button {
    width: 320px;
  }

  .business-button {
    width: 320px;
  }
  .socials-btn {
    padding: 0 15px;
  }
}

@media screen and (max-width: 991px) {
  .learn-button {
    margin-left: auto;
    margin-right: auto;
  }

  .business-button {
    margin-left: auto;
    margin-right: auto;
  }

  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }

  .left-col {
    margin-bottom: 94px;
  }
}

.signup-finetext {
  font-size: 0.75rem;
  margin: 0;
  line-height: 1.2;
  opacity: 0.5;
}

.form-group {
  margin-bottom: 12px !important;
}

label {
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0px;
}
.shdow {
  border-radius: 100%;
  position: relative;
  width: -1px;
  box-shadow: -1px 1.5px 80.5px 106px rgba(132, 55, 249, 0.13);
}

.googleLoginButton {
  position: relative;
  margin-top: 20px;
  justify-content: center;
  background-color: #5b5bee;
  padding: 8px 16px;
  font-size: 18px;
  color: white;
}

.googleLoginIcon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  background-color: #fff;
  width: fit-content;
  height: fit-content;
  padding: 2px 4px 3px;
  border-radius: 3px;
}

.googleLoginIcon > img {
  width: 30px;
  height: 30px;
}

.login-heading-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between !important;
}

.login-heading-container > .p {
  margin: 0;
  width: 100%;
  text-align: center;
}

.dashed {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 65%;
}
