.btn-dash {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  padding: 10px;
}
html {
  scroll-snap-type: y mandatory;
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available;
}

.yellow {
  background-color: #ffc107;
}
.purple {
  background-color: #8556f8;
}
.purple-text {
  color: #8556f8;
}
.zaio-blue {
  background-color: #0d1e3a;
}

.qr-app {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d1e3a;
}

.qr-app__videos {
  position: relative;
  top: 0;
  overflow: scroll;
  height: 100%;
  width: 100%;
  max-width: 540px;
  max-height: 960px;
  aspect-ratio: 9 / 16;
  scroll-snap-type: y mandatory;
  border-radius: 20px;
  /* this property will prevent going to the next on */
  /* overflow: hidden;  */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.qr-app__videos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.qr-app__videos {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.qr-icons {
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  * {
    font-size: 13px;
  }
  .btn-dash {
    display: none;
  }
  /* .app {
      height: 100vh;
      width: 100vw;
    } */
}
