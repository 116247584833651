.shdow {
  border-radius: 100%;
  position: relative;
  width: -1px;
  box-shadow: -1px 1.5px 80.5px 106px rgba(132, 55, 249, 0.13);
}

.sup-h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 33px;
  /* or 69% */

  text-align: center;

  color: #0d1e3a;
}
.sup-p {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;

  letter-spacing: 0.125px;

  color: #6e31f9;
}

.sup-p2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;

  letter-spacing: 0.125px;

  color: #0d1e3a;
}

.sup-tech-image {
  margin-top: 50px;
  margin-left: -160px;
  width: 120px;
}

.sup-tech-top {
  width: fit-content;
  margin: auto;
}

.sup-customize-learning-path {
  margin: auto;
  width: fit-content;
  background: #6e31f9;
  border-radius: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  cursor: pointer;
  /* or 137% */

  text-align: center;
  letter-spacing: 0.125px;

  color: #ffffff;
}

.sup-jump-pricing {
  display: block;
  margin: 30px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.125px;
  text-decoration-line: underline;
  text-align: center;
  color: #c4c4c4;
}
.sup-jump-pricing:hover {
  display: block;
  margin: 30px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.125px;
  text-decoration-line: underline;
  text-align: center;
  color: #c4c4c4;
}

.sup-page3-heading {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.form {
  width: 400px;
  max-width: 400px;
}
.form-group {
  margin-bottom: 12px;
  width: 100%;
  max-width: 400px;
}
.form-group label {
  font-weight: bold;
}

.social-signin span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.social-signin div {
  border-radius: 5px;
}
.link-social {
  text-align: center;
  flex: 1 0 0;
  justify-content: center;
}
.gmail {
  background: #4280ef;
}

.gmail div,
.linkdln div {
  border-radius: 5px;
}

.linkdln {
  background: #0a66c2;
}

.separation span {
  width: 100%;
}

.separation {
  display: flex;
  flex-direction: row;
}
.separation p {
  color: #8f8f8f;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.separation:before,
.separation:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #8f8f8f;
  margin: auto;
}

.sup-page3-options {
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 713px;
  padding: 12px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  cursor: pointer;
  font-weight: 500;
}
.sup-page3-options:hover {
  background-color: #c8d7fa;
}
.sup-page3-meta {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 33px;
  /* or 137% */

  text-align: center;
  letter-spacing: 0.125px;

  color: #0d1e3a;
}

.menu {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  flex-wrap: wrap;
}

.menu-option {
  height: 50px;
  width: 50px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 50%;
  border: 4px solid #0d1e3a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
}
.menu-option:hover {
  background-color: #c8d7fa;
}

.page-container {
  /* padding-top: 40px; */
  padding-bottom: 70px;
}
.page-form-container {
  margin: 30px auto;
}

.error {
  font-size: 14px;
  color: red;
  text-align: center;
}
.dob {
  width: 100%;
  height: 38px;
  border: solid 0.5px #ced4da;
  border-radius: 5px;
  padding-left: 10px;
  color: #495057;
}
.bottom-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}
