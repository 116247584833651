.mcq-container {
  height: 100vh;
  width: 100vw;
  background-color: var(--blue);
  display: grid;
  z-index: 99999999;
  overflow-y: scroll;

  .mcq-container-max {
    width: 50%;
    max-width: 700px;
    height: 100%;
    // background-color: aqua;
    place-self: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .mcq-body {
  }
}
