.invite-item {
  width: 100%;
  color: white;
  font-size: 0.8rem;
  border-radius: 6px;
  padding: 0.2rem 1rem;
  background: var(--grey);
  /* border: 2px dashed var(--blue); */
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.3rem;
  cursor: pointer;
}
.accepted-invite {
    background: var(--purple);
}
.received-invite {
    background: var(--yellow);
    border: 2px dashed var(--purple);
}
.invite-icon {
    font-size: 1rem;
    margin-right: 0.2rem;
}
