.redirect-container {
  width: 100%;
  margin-top: 0.2rem;
}

.redirect-container__course-info {
  margin-top: 1rem;
}

.redirect-container__list {
  list-style-type: circle;
  text-align: left;
}

.redirect-container__circle {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
