.reel-modal-title {
  text-overflow: ellipsis;
}

.reward {
  background: "#0d1e3a";
  color: "white";
}

.modal-with-video-container {
  display: flex;
  flex-direction: row;
}

.left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-container {
  object-fit: contain;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-with-video-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .modal-with-video-container {
    flex-direction: column;
  }
  .left-container {
    width: 100%;
  }
  /* .app {
    height: 100vh;
    width: 100vw;
  } */
}
