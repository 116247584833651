.container {
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
  color: #ffffff;
}

.title {
  color: #ffffff;
  margin-bottom: 20px;
}

.title > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
  color: #909090;
  transform: translateX(-7px);
  margin-bottom: 30px;
}

.inputGroup {
  position: relative;
  border: 1px solid #8d959c;
  border-radius: 4px;
  /* margin-top: 24px; */
  width: 100%;
}

.inputGroup > .input {
  background-color: unset;
  border: unset;
  min-height: 50px;
  padding-right: 40px;

  font-family: "Open Sans";
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;
  color: #59677e;
}

.searchIcon {
  color: #fff;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
}

.subHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  /* identical to box height, or 24px */

  /* display: flex; */
  /* align-items: center; */

  color: #ffffff;
}

.tableContainer {
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px !important;
  box-sizing: border-box;
  padding: 2px 23px;
}

.tableHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  /* or 31px */

  margin: 50px 0 35px 0;

  color: #ffffff;
}

.tableHead {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  letter-spacing: -0.01em;
  text-transform: uppercase;

  color: #909090;
  /* height: 60px; */
}

.tableHeadData {
  /* height: 60px; */
  padding: 20px 8px 20px 0 !important;
  width: 90px !important;
}

.tableBodyData {
  padding: 25px 8px 25px 0 !important;
}

.tabelBody {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.tableRow {
  border-top: 2px dashed rgba(255, 255, 255, 0.1) !important;
  height: 70px;
}

.statusBtn {
  background-color: #8437f9;
  padding: 7px 20px;
  border-radius: 4px;
}

.editBtn {
  background: transparent !important;
  width: 100% !important;
  height: 50px;
  margin-top: 49px;
  border: 1px solid #f9c80e;
}

.submitBtn {
  background-color: #f9c80e;
  width: 100% !important;
  height: 50px;
  margin-top: 49px;
}

@media screen and (max-width: 450px) {
  .container {
    padding: 28px 15px 48px 15px;
  }
}

@media screen and (max-width: 391px) {
  .container {
    padding: 28px 10px 48px 10px;
  }
}

@media screen and (max-width: 376px) {
  .container {
    padding: 28px 5px 48px 5px;
  }
}
