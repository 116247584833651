
.list{
    padding: 5px;
    overflow-y: scroll;
    min-height: 400px;    
}
.list *{
    margin: 5px 0;
    background-color: white;
}
@media screen and (min-width: 768px){
    .list{
        min-height: 80vh;
        max-height: 400px;
    }
}

.sidebarHr {
    background:white;
    padding: 0;
    width: 100%;
    margin: 0px;
}

.adminItems {
    color: #16325B ;
    background-color: #fff;
    font-weight: bold;
    border: 1px solid #bbb;
}

.adminItems:hover {
    background-color: #16325B ;
    color: #fff;
}