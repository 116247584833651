.floating-challenge-btn {
    position: fixed;
    height: 5vh;
    width: 100vw;
    background-color: transparent;
    color: white;
    font-style: italic;
    font-size: 1rem;
    border: none;
    z-index: 5;
    bottom: 0;
    right: 10;

    p {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    
}