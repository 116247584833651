.dropdown-toggle::after {
  color: #909090;
}

.navbar-light .navbar-toggler {
  background-color: #fff;
}

.navbar-light .navbar-toggler-icon {
  width: 20px;
  height: 20px;
}

.offcanvas-top {
  height: fit-content;
}

.offcanvas-header {
  width: fit-content;
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.offcanvas-header .btn-close {
  background-color: #fff;
}

@media screen and (min-width: 992px) {
  .dropdown-menu.show {
    display: block;
    transform: translate(-50px, 10px);
    border: 1px dashed rgba(255, 255, 255, 0.1);
  }
}

@media screen and (max-width: 992px) {
  .offcanvas-body {
    background-color: #1f2c42;
  }
}
