.container {
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
}

.container1 {
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  /* margin-left: 185px; */
  margin: 0;
}

.notesContainer {
  display: flex;
}

.title {
  color: #ffffff;
  margin-bottom: 20px;
}

.subContainer {
  margin-top: 1rem;
}

.leftContainer {
  /* width: 30%; */
  color: white;
}

.rightContainer {
  /* width: 70%; */
  color: #fff;
  padding-left: 10px;
}

.cardContainer {
  width: 350px;
  background: #1f2c42;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 20px 30px;
  color: #fff;

  /* font-family: "Open Sans"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #ffffff;
  margin-top: 1rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.userImg {
  width: 93px;
  border-radius: 50%;
}

.userImg > img {
  width: 100%;
  border-radius: 50%;
}

.iconText {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.iconText > img {
  margin-right: 15px;
}

.cardHeaderHeading {
  font-family: "Apercu Pro";
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 8px;
  margin-top: 8px;
}

.cardDiscription {
}

.cardFooter {
  margin-top: 50px;
}

.subHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 120%;
  /* or 31px */

  color: #ffffff;
  margin-top: 60px;
}

.streaksBox {
  width: 350px;
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 20px 30px;
  color: #fff;
  margin-top: 20px;
}

.streaksBoxHeadign {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  color: #ffffff;
}

.streaksBoxNumber {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */

  color: #f9c80e;
  margin-right: 12px;
  margin-bottom: 0;
}

.streaksBoxContent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  color: #ffffff;
  margin: 0;
}

/* **********latest course********** */

.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin-top: 20px;
  width: 100%;
  /* height: 110px; */
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
}

.iconsandTitle {
  display: flex;
}

.contentTitle {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #909090;
}

.proIcon {
  background: #f9c80e;
  border-radius: 4px;
  color: #2c3131;
  font-size: 12px;
  padding: 3px 20px;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;
  margin: 3px;
}
.proIcon:hover {
  background: var(--purple);
  color: white;
}
.selectedFilterBtn {
  background: var(--purple);
  color: white;
}

.contentHeading {
  font-family: "Apercu Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  padding-right: 135px;
}

.progressBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 140px;
}

.mobileViewProgressBar {
  display: none;
}

.progressBar,
.lable {
  position: relative;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.percentageIcon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.courseProgressBar {
  margin-top: 5px !important;
  height: 5px !important;
  background: #e6e6e6;
}

.courseProgressBar > * {
  background-color: #f9c80e !important;
}

.p {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.viewAllText {
  width: fit-content;
  margin-left: auto !important;
  cursor: pointer;
  margin-top: 15px;
}

.underlineP {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  letter-spacing: -0.01em;
  text-decoration-line: underline;
  cursor: pointer;

  color: #909090;
}

/* **************************************** */

.badgesContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.badgesBox {
  position: relative;
  padding: 24px;
  padding-bottom: 50px;
  background: #1f2c42;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  min-width: 334px;
  width: 48%;
}

.badgesDate {
  position: absolute;
  left: 20px;
  bottom: 20px;
  margin: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #909090;
}

.shareLinkContainer {
  display: flex;
  padding: 10px 0;
  align-items: center;
  cursor: pointer;
}

.poster_header_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.profile_pic{
  height: 130px;
  width: 130px;
  background: #f9c80e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_avtar {
  width: 120px !important;
  height: 120px !important;
  object-fit: cover;
  border-radius: 50%;
}
.social_icons{
  height: 20px !important;
  width: 20px !important;
  margin-left: 10px;
}

.profile_detail{
  color: #2c3131;
  margin-left: 20px;
}

.profile_tab{
  background: rgba(var(--bs-success-rgb))!important;;
  text-align: center;
  width: 100px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.profile_tab_right{
  background: #8437f9;
  padding: 5px;
  text-align: center;
  width: 100px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 10px;
}

.activityChartBox {
  overflow-x: auto;
}

.activityChartBox > * {
  padding: 30px;
  min-width: 800px;
}

.bg_poster_image{
  position: absolute;
}

@media screen and (max-width: 450px) {
  .container {
    padding: 28px 15px 48px 15px;
  }

  .container1 {
    padding: 28px 15px 48px 15px;
  }

  .cardContainer {
    width: 100%;
    min-width: 270px;
  }

  .rightContainer {
    padding-left: 12px;
    margin-top: 50px;
  }

  .contentHeading {
    padding-right: 0px;
    padding-bottom: 10px;
  }

  .box {
    height: 130px;
  }

  .box > * {
    width: 100%;
  }

  .progressBar {
    display: none;
  }

  .mobileViewProgressBar {
    display: flex;
    margin-top: 5px;
  }

  .contentHeading {
    font-size: 14px;
  }

  .contentTitle {
    font-size: 12px;
  }
}

@media screen and (max-width: 391px) {
  .container {
    padding: 28px 10px 48px 10px;
  }

  .container1 {
    padding: 28px 10px 48px 10px;
  }
}

@media screen and (max-width: 376px) {
  .container {
    padding: 28px 5px 48px 5px;
  }

  .container1 {
    padding: 28px 10px 48px 10px;
  }

  .cardText > .right {
    right: 16px;
    bottom: 16px;
  }
}
