
.list{
    padding: 5px;
    overflow-y: scroll;
    min-height: 400px;    
}
.list *{
    margin: 5px 0;
    background-color: white;
}

@media screen and (min-width: 768px){
    .list{
        min-height: 80vh;
        max-height: 400px;
    }
}
