.dashboard-container {
  /* padding: 3rem; */
  padding: 28px 48px 48px 48px;
  background-color: #0d1e3a;
  height: 100%;
  width: 100%;
  // max-width: 1200px;
  /* margin-left: 185px; */
  margin: 0;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem;
  margin-left: 0;
  margin-bottom: 0;
}
.title {
  color: #ffffff;
  /* margin-top: 3rem; */
}
.cardBlock {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: flex-start; */
  /* margin-top: 3rem; */
  justify-content: flex-start;
  z-index: -1;
  max-height: max-content;

  /* margin: 3rem 0 0 0; */
}

// Daling woith continue learning issues hover and stuff
.dash-container {
  position: relative;
  width: 350px;
  /* min-height: 350px; */
  background: #1f2c42;
  /* border: 1px dashed rgba(255, 255, 255, 0.1); */

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding: 20px; */
  margin: 10px;

  z-index: 0;
}

.upgrade-container {
  background: #f8c936 !important;
  flex-grow: 1;
  color: white;

  img {
    object-fit: contain;
    overflow: hidden;
  }
}

.deferred-student-container {
  flex-grow: 1;
  color: white;
}

.upgrade-container__mobile-cl {
  height: 600px;
  max-height: 600px;
}

.upgrade-container:hover {
  // transform: scale(1.05);
}

.upgrade-container-info {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  color: black;
}
.upgrade-container-btns {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: black;
}

#continueLearning {
  // position: absolute;
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  cursor: none;
  transform: scale(1.2);
  // opacity: ${(props) => (props.hideVideo ? 0 : 1)};

  // &::cue {
  //   color: #eee;
  //   z-index: 4;
  //   text-shadow: #222 0 0 5px;
  //   background: none;
  // }
}

.mobile-player-cl-container {
  height: 600px;
  min-width: 300px;
  max-width: 400px;
  // width: 100%;
  align-items: center;
  flex: 1;
}
.mobile-player-cl {
  height: 100%;
  flex: 1;
  // transform: scale(1.2);
}

.cl-container {
  flex-grow: 3;
  /* border: 1px dashed var(--yellow); */
  position: relative;
  overflow: hidden;
}
.cl-container:hover {
  /* transform: scale(1.06); */
  /* margin: 0 40px; */
}

.dash-competition {

  display: flex;
  justify-content: start;
  align-items: center;
  margin: 1rem;
  margin-left: 0;
  img {
    border-radius: 50%;
    width: 100px;
    min-width: 50px;
  }
}
.dash-message {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  // margin: 0 1rem;
  border: #eaeaea solid 1px;
  border-radius: 3px;
  // margin-left: -10px;

  p {
    font-weight: 500;
    position: relative;
  }
}
.dash-comp-icon {
  font-size: 3rem;
  color: white;
  z-index: 2;
  margin-right: -22px;
}
.dash-message-parent {
  display: flex;
  align-items: center;
}
.dash-competition-card {
  margin: 1rem;
  border-radius: 3px;
  padding: 1rem;

  // min-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

@media only screen and (max-width: 600px) {
  .container1 {
    padding: 1rem 2rem;
    margin-right: 1rem;
  }
  .cardBlock {
    display: block;
    max-height: fit-content;
  }
  .cl-container {
    width: 100%;
  }
  .dash-container {
    min-height: 250px;
  }
  .mobile-player-cl-container {
    display: block;
  }
  .upgrade-container {
    width: 100%;
    height: 350px;
  }

  .dash-comp-icon {
    font-size: 5rem;
    margin: 0;
    padding: 0;
    margin-right: -30px;
    z-index: 3;
    svg {
      transform-origin: 50% 50%;
    }
  }

  /* .app {
    height: 100vh;
    width: 100vw;
  } */
}
