.company-container {
    text-align: center;
}
.company-image-company {
  width: 6rem;
  border-radius: 50%;
}
.job-requirements-list {
  list-style-type: circle;
  text-align: left;
  line-height: 140%;
}