.cl-video-title {
  position: absolute;
  transition: all 0.2s linear ease-in-out;
  top: 0;
  padding: 1rem;
  z-index: 2;
  transition: all 0.2s ease-out;

  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
